import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";


const FacebookIcon = dynamic(() => import("@mui/icons-material/Facebook"), {
    ssr: false,
});
const InstagramIcon = dynamic(() => import("@mui/icons-material/Instagram"), {
    ssr: false,
});
const LinkedInIcon = dynamic(() => import("@mui/icons-material/LinkedIn"), {
    ssr: false,
});
const TwitterIcon = dynamic(() => import("@mui/icons-material/Twitter"), {
    ssr: false,
});

const FooterPromise = dynamic(() => import("./FooterPromise"), {
    ssr: true,
});

const TrustpilotWidget = dynamic(() => import("../HomeComponent/TrustpilotWidget"), {
    ssr: true,
});

const FooterCompany = dynamic(() => import("./FooterCompany"), {
    ssr: true
});
const FooterContactUs = dynamic(() => import("./FooterContactUs"), {
    ssr: true
});

const FooterInformation = dynamic(() => import("./FooterInformation"), {
    ssr: true
});

const FooterDesktop = () => {

    const template_settings = useSelector(state => state.globalReducer.template_settings);
    const footer_layout_grid = useSelector(state => state.globalReducer.footer_layout_grid);
    const footer_links = useSelector(state => state.globalReducer.footer_links);
    const gridSize = 12/6;

    const checkFooterElement = (key, type) => {
        switch(key){
            case 'footer_show_company_links':
                return (
                    <>
                        {template_settings[key] == 'yes' && <FooterCompany links={footer_links.company_links} type={type}/>}
                    </>
                )
            case 'footer_show_company_promises':
                return (
                    <>
                        {template_settings[key] == 'yes' && <FooterPromise type={type}/>}
                    </>
                )
            case 'footer_show_information_links':
                return (
                    <>
                        {template_settings[key] == 'yes' && <FooterInformation links={footer_links.information_links} type={type}/>}
                    </>
                )
            case 'footer_show_trustpilot':
                return (
                    <>
                        {template_settings[key] == 'yes' && <TrustpilotWidget type={type} />}
                    </>
            )
            case 'footer_show_contact_us':
                return (
                    <>
                        {template_settings[key] == 'yes' && <FooterContactUs type={type} />}
                    </>
            )
            case 'footer_show_socials':
                return (
                    template_settings[key] == 'yes' && <>
                        <Typography className="f-size_3 uppercase font-regular mb-3" component="h5">Our Socials</Typography>

                        <div className="mt-2">
                            <Stack  spacing={1}>
                                <a href="https://www.facebook.com/furniturehireuk/" target="_blank"><IconButton  size="medium"  aria-label="Facebook" color="primary">
                                    <FacebookIcon />
                                </IconButton></a>
                                <a href="https://www.instagram.com/furniturehireuk/" target="_blank"><IconButton aria-label="Instagram" color="primary">
                                    <InstagramIcon />
                                </IconButton></a>
                                <a href="https://www.linkedin.com/company/furniture-hire-uk" target="_blank"><IconButton color="primary" aria-label="Linked In">
                                    <LinkedInIcon />
                                </IconButton></a>
                                <a href="https://twitter.com/furniturehireuk" target="_blank"><IconButton color="primary" aria-label="Twitter">
                                    <TwitterIcon />
                                </IconButton></a>
                            </Stack>
                        </div>
                    </>
                )
        }
    }

    return (
        <Grid justifyContent={"space-evenly"} spacing={0} className="boxed-padding" container>
      

            {footer_layout_grid && footer_layout_grid.map((element, idx) => (
                <Grid key={idx} lg={gridSize} className="pl-2 pr-2" item>
                    {checkFooterElement(element.footer_key, 'desktop')}
                </Grid>
            ))}
{/* 
{template_settings.footer_show_company_promises == 'yes' && <Grid lg={gridSize} className="pl-2 pr-2" item>
                <FooterPromise type="desktop"/>
            </Grid>}

            {template_settings.footer_show_company_links == 'yes' && (
                <Grid lg={gridSize} className="pl-2 pr-2" item>
                    <FooterCompany type="desktop"/>
                </Grid>
            )}

            {template_settings.footer_show_information_links == 'yes' && (
                <Grid lg={gridSize} className="pl-2 pr-2" item>
                    <FooterInformation type="desktop"/>
                </Grid>
            )}

            <Grid lg={gridSize} className="pl-2 pr-2" item>
                <FooterContactUs
                    type="desktop"
                />
            </Grid>
 */}

            {/* {template_settings.footer_show_socials == 'yes' && (
                <Grid lg={gridSize} className="pl-2 pr-2 text-center" item>
                    <Typography className="f-size_3 uppercase font-regular mb-3" component="h5">Our Socials</Typography>

                    <div className="mt-2">
                        <Stack  spacing={1}>
                            <a href="https://www.facebook.com/furniturehireuk/" target="_blank"><IconButton  size="medium"  aria-label="Facebook" color="primary">
                                <FacebookIcon />
                            </IconButton></a>
                            <a href="https://www.instagram.com/furniturehireuk/" target="_blank"><IconButton aria-label="Instagram" color="primary">
                                <InstagramIcon />
                            </IconButton></a>
                            <a href="https://www.linkedin.com/company/furniture-hire-uk" target="_blank"><IconButton color="primary" aria-label="Linked In">
                                <LinkedInIcon />
                            </IconButton></a>
                            <a href="https://twitter.com/furniturehireuk" target="_blank"><IconButton color="primary" aria-label="Twitter">
                                <TwitterIcon />
                            </IconButton></a>
                        </Stack>
                    </div>
                </Grid>
            )} */}

            {/* <Grid lg={gridSize} className="pl-2 pr-2" item>
                {template_settings.footer_show_trustpilot == 'yes' && <TrustpilotWidget
                    type="desktop"
                />}
            </Grid> */}
        </Grid>
    )
}

export default FooterDesktop